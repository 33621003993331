<template>
  <div class="pt-12 mt-6">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="display-1 mb-4 font-weight-bold">
            Kişisel Verilerin Korunması
          </h1>
          <p>
            UTEx kapsamında kişisel verilerin işlenmesine ilişkin aydınlatma
            metni
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-4">
          <h3>
            1. Aydınlatma Metni’nin Amacı ve Şirketimizin Veri Sorumlusu Konumu
          </h3>
          <p>
            Şirketimiz UTE Akademi A.Ş. (“UTE Akademi", "UTEx" ve/veya
            “Şirket”), müşterilere ilişkin kişisel veriler bakımından 6698
            sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) kapsamında “veri
            sorumlusu” sıfatına sahip olup işbu Aydınlatma Metni ile söz konusu
            Kanun uyarınca müşterilerin UTE Akademi tarafından gerçekleştirilen
            kişisel veri işleme faaliyetleri hakkında aydınlatılması
            hedeflenmektedir.
          </p>
          <h3>2. Müşterilere Ait Kişisel Verilerin İşlenme Amacı</h3>
          <p>
            Müşterilere ait kişisel veriler, UTE Akademi tarafından sunulan ürün
            ve hizmetlerden ilgili kişileri faydalandırmak için gerekli
            çalışmaların iş birimleri tarafından yapılması ve ilgili iş
            süreçlerinin yürütülmesi, UTE Akademi tarafından yürütülen ticari
            faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından
            gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin
            yürütülmesi, UTE Akademinin ticari ve/veya iş stratejilerinin
            planlanması ve icrası, UTE Akademinin ve UTE Akademi ile iş ilişkisi
            içerisinde olan ilgili kişilerin hukuki, teknik ve ticari iş
            güvenliğinin temini de dahil olmak üzere Kanun’un 5. ve 6.
            maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
            çerçevesinde işlenmektedir.
          </p>
          <h3>
            3. Müşterilerin Açık Rızası Doğrultusunda İşlenecek Kişisel Veriler
            ve İşleme Amaçları
          </h3>
          <p>
            Açık rıza doğrultusunda işlenecek kişisel veriler ve işleme amaçları
            Rıza Metni’nde belirtilmiştir.
          </p>
          <h3>4. Müşterilere Ait Kişisel Verilerin Aktarımı</h3>
          <p>
            Müşterilere ait kişisel veriler, UTE Akademi tarafından sunulan ürün
            ve hizmetlerden ilgili kişileri faydalandırmak için gerekli
            çalışmaların iş birimleri tarafından yapılması ve ilgili iş
            süreçlerinin yürütülmesi, UTE Akademi tarafından yürütülen ticari
            faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından
            gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin
            yürütülmesi, UTE Akademinin ticari ve/veya iş stratejilerinin
            planlanması ve icrası, UTE Akademinin ve UTE Akademi ile iş ilişkisi
            içerisinde olan ilgili kişilerin hukuki, teknik ve ticari iş
            güvenliğinin temini için gerekli olan aktivitelerin planlanması ve
            icrası da dahil olmak üzere Kanun’un 8. ve 9. maddelerinde
            belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde UTE
            Akademi A.Ş. ve UTE Akademi Şirket yetkilileri, iştiraklerimiz, iş
            ortaklarımız, tedarikçilerimiz, hissedarlarımız, kanunen yetkili
            kamu kurum ve kuruluşları ile özel kurumlar ile paylaşılabilecektir.
          </p>
          <h3>5. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</h3>
          <p>
            Kişisel veriler, müşterilerden elektronik ortamda toplanmaktadır.
            Yukarıda belirtilen hukuki sebeplerle toplanan kişisel veriler
            Kanun’un 5. ve 6. maddelerinde ve bu Aydınlatma Metni ve açık rıza
            gerekli olduğu hallerde Rıza Metni’nde belirtilen amaçlarla
            işlenebilmekte ve aktarılabilmektedir.
          </p>
          <h3>6. Kişisel Veri Sahibi Olarak Müşterilerin Hakları</h3>
          <p>
            Kanun’un 11. maddesi uyarınca veri sahipleri; (i) kendileri ile
            ilgili kişisel veri işlenip işlenmediğini öğrenme, (ii) kişisel
            verileri işlenmişse buna ilişkin bilgi talep etme, (iii) kişisel
            verilerin işlenme amacını ve bunların amacına uygun kullanılıp
            kullanılmadığını öğrenme, (iv) yurt içinde veya yurt dışında kişisel
            verilerin aktarıldığı üçüncü kişileri bilme, (v) kişisel verilerin
            eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
            isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
            üçüncü kişilere bildirilmesini isteme, (vi) Kanun ve ilgili diğer
            kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini
            gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin
            silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan
            işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
            isteme, (vii) işlenen verilerin münhasıran otomatik sistemler
            vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir
            sonucun ortaya çıkmasına itiraz etme ve (viii) kişisel verilerin
            kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
            zararın giderilmesini talep etme haklarına sahiptir.
          </p>
          <p>
            Söz konusu hakların kullanımına ilişkin talepler, kişisel veri
            sahipleri tarafından UTE Akademiye iletilebilecektir. UTE Akademi,
            söz konusu talepleri değerlendirerek 30 gün içerisinde
            sonuçlandıracaktır. UTE Akademinin taleplere ilişkin olarak Kişisel
            Verileri Koruma Kurulu tarafından belirlenen (varsa) ücret tarifesi
            üzerinden ücret talep etme hakkı saklıdır.
          </p>
          <h3>Rıza Metni</h3>
          <p>
            Şirketimiz UTE Akademi A.Ş. (“UTE Akademi”, "UTEx" ve/veya
            “Şirket”), müşterilere ilişkin kişisel veriler bakımından 6698
            sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) kapsamında “veri
            sorumlusu” sıfatına sahip olup işbu Rıza Metni ile söz konusu Kanun
            uyarınca müşterilerin UTE Akademi tarafından gerçekleştirilen ve
            aşağıda belirtilen kişisel veri işleme faaliyetlerine ilişkin açık
            rızalarının temini hedeflenmektedir.
          </p>

          <p>
            Kanun’un 5/2 ile 6/3 maddesinde yer alan kişisel veri işleme
            şartlarının karşılanamadığı aşağıdaki durumlar için UTE Akademi
            tarafından kişisel verilerin işlenebilmesi için müşterilerin açık
            rızasının alınması gerekmektedir. Açık rıza gerektirmeyen süreçlere
            ilişkin olarak Aydınlatma Metni kapsamında veri işleme faaliyetleri
            sürdürülmekte olup açık rıza gerektiren süreçlerde de Aydınlatma
            Metni’nde belirtilen temel prensiplere uygun veri işlenmektedir.
          </p>

          <p>
            Bu kapsamda müşterilerin kişisel verileri; müşterilere yönelik
            kampanyaların oluşturulması, UTE Akademi iş ortakları tarafından UTE
            Akademi müşterilerine UTE Akademide kullanabilecekleri özel
            fırsatların sunulması, çapraz satış yapılması, hedef kitle
            belirlenmesi, Müşteri hareketlerinin takip edilerek kullanıcı
            deneyimini arttırıcı faaliyetlerin yürütülmesi, UTE Akademiye ait
            internet sitesi ile mobil uygulamanın işleyişinin geliştirilmesi ve
            Müşteri ihtiyaçlarına göre kişiselleştirilmesi, doğrudan ve doğrudan
            olmayan pazarlama, kişiye özel pazarlama ve yeniden pazarlama
            faaliyetlerinin yürütülmesi, kişiye özel segmentasyon, hedefleme,
            analiz ve şirket içi raporlama faaliyetlerinin yürütülmesi, pazar
            araştırmaları, müşteri memnuniyeti aktivitelerinin planlanması ve
            icrası, UTE Akademinin sunduğu ürün ve hizmetlerin ilgili kişilerin
            beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre
            özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması ile
            müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası
            amaçları da dahil olmak üzere genel anlamda UTE Akademinin ürün
            ve/veya hizmetlerinin satış ve pazarlama süreçlerinin planlanması ve
            icrası, UTE Akademinin sunduğu ürün ve/veya hizmetlere bağlılık
            oluşturulması ve/veya arttırılması süreçlerinin planlanması ve
            icrası kapsamında Müşteri’nin vereceği onayı doğrultusunda
            işlenebilecek ve bu amaçlara yönelik olarak Aydınlatma Metni’nde
            belirtilen taraflarla paylaşılabilecektir.
          </p>
          <p class="small text-right">
            Bu belge, en son 14 Ağustos 2020 tarihine güncellenmiştir.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Kişisel Verilerin Korunması"
  }
};
</script>
